.solutions-grid {
  display: flex;
  flex-wrap: wrap;
  max-width: 1340px;
  margin: 55px auto 35px;

  > * {
    width: calc(33.333333% - 15px);
    margin-right: 20px;
    margin-bottom: 24px;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @include max-width($mobile) {
    flex-direction: column;

    > * {
      width: 100%;
      margin-right: 0;

      &:nth-child(odd) {
        margin-right: 22px;
      }
    }
  }
}

.solution-cell {
  padding: 69px 47px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0px 7px 13px 0 rgba(74, 74, 74, 0.15);
  background-color: #fafafa;
  transition: all 150ms ease;
  transform: scale(1);

  img {
    margin-bottom: 29px;
    min-height: 72px;
  }

  h3 {
    margin-bottom: 20px;
    margin-top: 0;
    color: $black;
    transition: all 150ms ease;
  }

  p {
    margin: 0;
    color: $black;
    transition: all 150ms ease;
  }

  .btn {
    margin-top: 67px;
    border: 2px solid transparent;
    transition: all 150ms ease;
  }

  &:hover {
    background-color: $green;
    transform: scale(0.96);

    h3,
    p,
    .btn {
      color: #fff;
    }

    .btn {
      border-color: #fff;
    }
  }

  &.partner-cell {
    justify-content: center;
    img {
      min-height: 0;
    }
  }
}

.solutions-subnav-wrap {
  display: flex;
  justify-content: center;
}

.solutions-subnav {
  display: flex;
  align-items: center;
  margin: 58px auto 35px;
  padding-bottom: 8px;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  overflow-x: auto;

  .link {
    padding: 6px 15px;
    font-weight: 400;
    color: #678896;
    letter-spacing: 0.15px;
    text-decoration: none;
    transition: all 150ms ease;
    border-radius: 19px;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      font-weight: 500;
      background-color: #f2f2f2;
    }

    &:hover {
      color: $blue;
      background-color: #f9f9f9;
    }
  }

  .carousel {
    width: 90%;
    margin: 0 auto;
  }

  .slider-action {
    position: absolute;
    width: 12px;
    height: 12px;
    top: 0;
    bottom: 0;
    margin: auto;
    appearance: none;
    padding: 4px;
    border: none;
    outline: none;
    background-color: #fff;

    &.next {
      right: 0;
    }

    &.prev {
      left: 0;
    }

    div {
      border-right-color: #98b5c0;
      border-bottom-color: #98b5c0;
    }
  }

  .slide {
    padding-bottom: 18px !important;
    padding-top: 18px !important;
  }

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track {
    background-color: #f9f9f9;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}

.center-button {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.partners-grid .solution-cell img {
  max-width: 100%;
  display: block;
}
