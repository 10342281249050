.select-wrap {
  position: relative;
  label {
    color: $black;
    font-size: 17px;
    line-height: 23px;
    letter-spacing: 0.85px;
    font-weight: 600;
    display: block;
    margin-bottom: 24px;
  }

  .value {
    padding: 13px 17px;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    background-color: #f6f6f6;
    font-size: 17px;
    line-height: 23px;
    color: $blacker;
    resize: none;
    width: 100%;
    transition: all 150ms ease;
    cursor: pointer;
    position: relative;

    &:hover {
      border-color: $lightblue;
    }
  }

  .chevron {
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: inline-block;
    border-right: 2px solid $black;
    border-bottom: 2px solid $black;
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    transition: all 150ms ease;

    &.open {
      transform: rotate(-135deg);
    }
  }

  .placeholder {
    color: #a6a6a6;
    letter-spacing: 0.17px;
  }

  .dropdown {
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    transform: translateY(-35px);
    position: absolute;
    top: calc(100% + 8px);
    width: 100%;
    max-height: 220px;
    overflow: auto;
    border-radius: 3px;
    box-shadow: 0 0 13px 0 rgba(74, 74, 74, 0.1);
    transition: all 150ms ease;
    will-change: opacity, transform, z-index, pointer-events;

    &.open {
      opacity: 1;
      z-index: 5;
      pointer-events: auto;
      transform: translateY(0);
    }
  }

  .option {
    background-color: #fff;
    cursor: pointer;
    font-size: 17px;
    line-height: 23px;
    color: $blacker;
    resize: none;
    width: 100%;
    transition: all 150ms ease;
    padding: 13px 17px;

    &:hover {
      background-color: #e3f5fc;
    }

    &.selected {
      font-weight: 600;
    }
  }
}
