section {
  padding: 30px 10px;

  &.ultimate-solution {
    img {
      max-width: none;
    }
    .section-heading {
      transform: translateX(-66px);
    }
    @include max-width($mobile) {
      img {
        max-width: 100%;
      }
      .section-heading {
        transform: translateX(0);
      }
    }
  }

  &.explore {
    background-image: url('/images/backgrounds/explore-bg.svg');
    padding-top: 10vw;
    margin-top: -145px;

    img {
      max-width: none;
    }
    .section-heading {
      transform: translateX(54px);
    }

    @include max-width($mobile) {
      padding-top: 135px;
      margin-top: 0;
      img {
        max-width: 100%;
      }
      .section-heading {
        transform: translateX(0);
      }
    }
  }

  &.spaced {
    padding: 95px 10px;
  }

  @include max-width($mobile) {
    padding: 35px 10px;
  }
}

.section-heading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 1340px;

  h3 {
    margin-top: 0;
    margin-bottom: 33px;
  }

  h1 {
    margin-bottom: 39px;
    margin-top: 0;
  }

  p {
    margin-top: 0;
    margin-bottom: 37px;
  }

  .btn {
    display: inline-flex;
  }

  &.left {
    justify-content: flex-start;
    margin-right: auto;
  }

  &.center {
    justify-content: center;
    text-align: center;
    margin: 0 auto;
  }

  &.right {
    justify-content: flex-end;
    margin-left: auto;
  }

  &.heading-column {
    flex-direction: column;
    h1 {
      margin-bottom: 16px;
    }
  }

  &.black {
    h1 {
      color: $blue;
    }

    h3 {
      color: $blacker;

      b {
        color: #33b7ea;
      }
    }

    p {
      color: $black;
    }
  }

  &.white {
    color: #fff;

    h3 {
      color: #fff;
    }

    p {
      color: #fff;
    }
  }

  &.centered {
    justify-content: center;
    text-align: center;
    margin: 0 auto;

    .image {
      margin-right: 45px;
    }

    .text {
      text-align: left;
    }
  }

  &.solutions-section {
    max-width: 1100px;
    margin: 0 auto;
    justify-content: center;

    h3 {
      display: flex;
      align-items: center;
      img {
        max-width: 35px;
        margin-right: 19px;
      }
    }

    .text {
      text-align: left;
      margin-right: 45px;
    }
  }

  &.separated {
    margin: 65px auto;

    p {
      margin-bottom: 0;
    }
  }

  .text {
    &.trimmed-width {
      max-width: 850px;
    }
  }

  @include max-width($mobile) {
    flex-direction: column;
    padding-left: 10px;
    padding-right: 15px;
    > * {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.take-first-step {
  padding: 12vh 0 0;
  background-image: url(/images/take-first-step/bg.svg);
  background-repeat: no-repeat;
  background-position: center center;

  .img {
    margin: 35px auto 0;
    text-align: center;

    img {
      display: inline-block;
    }
  }

  .text {
    padding-top: 60px;
    padding-bottom: 90px;
    text-align: center;
    background-color: $green;
    p {
      margin: 0 0 38px;
      color: #fff;
      font-size: 20px;
      letter-spacing: 0.2px;
    }
  }

  @include max-width($mobile) {
    .text {
      padding: 45px 10px;
    }
  }
}

.customized-cultivation {
  min-height: 975px;
  background-image: url(/images/backgrounds/customized-bg.svg);
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 50px;
  padding: 170px 10px 150px;

  .inner {
    max-width: 1200px;
    margin: 0 auto;

    img {
      display: block;
      max-width: 505px;
      height: auto;

      @include max-width($mobile) {
        max-width: 100%;
      }
    }
  }

  .image-left {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    p {
      color: $body;
      margin-left: 69px;
      padding-right: 54px;
    }

    @include max-width($mobile) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 28px;

      p {
        margin-right: 0;
        margin-left: 0;
        padding-left: 9px;
      }
    }
  }

  .image-right {
    display: flex;
    align-items: center;

    p {
      margin-right: 69px;
      padding-left: 54px;
    }

    @include max-width($mobile) {
      flex-direction: column;
      align-items: flex-start;

      img {
        order: 0;
      }

      p {
        margin-right: 0;
        padding-left: 9px;
        order: 1;
      }
    }
  }

  @include min-width($mobile) {
    margin-top: -110px;
  }

  @include max-width($mobile) {
    min-height: 0;
    padding: 45px 15px;
    background-image: none;
    background-color: #f7f7f7;
  }
}

.tos-text {
  max-width: 1340px;
  margin: 25px auto;
  p {
    color: $black;
    font-size: 17px;
    font-weight: 600;
    margin: 36px 0;
  }
  &.padded {
    padding-left: 85px;
  }
}

.chevron-right {
  margin-left: 6px;
  display: inline-block;
  border-right: 2px solid $green;
  border-bottom: 2px solid $green;
  width: 6px;
  height: 6px;
  transform: rotate(-45deg);
  transition: all 150ms ease;
}

.chevron-up {
  margin-left: 6px;
  display: inline-block;
  border-right: 2px solid $green;
  border-bottom: 2px solid $green;
  width: 6px;
  height: 6px;
  transform: rotate(-135deg);
  transition: all 150ms ease;
}

.chevron-down {
  margin-left: 6px;
  display: inline-block;
  border-right: 2px solid $green;
  border-bottom: 2px solid $green;
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
  transition: all 150ms ease;
}
