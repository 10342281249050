@import '~normalize.css/normalize.css';
@import './variables.scss';
@import './mixins.scss';
@import './typography.scss';
@import './buttons.scss';
@import './header.scss';
@import './section.scss';
@import './features.scss';
@import './solutions.scss';
@import './hero.scss';
@import './testimonials.scss';
@import './footer.scss';
@import './get-started-modal.scss';
@import './forms.scss';
@import './select.scss';

.content-wrap {
  padding: 15px 15px 45px;

  &.no-sides {
    padding-left: 0;
    padding-right: 0;
  }
}

img {
  @include max-width($mobile) {
    max-width: 100%;
    height: auto;
  }
}

*,
*:after,
*:before {
  box-sizing: border-box;
}
