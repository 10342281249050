.footer {
  padding: 48px 15px 40px;
  background-color: #2b2f3a;

  .inner {
    max-width: 1100px;
    margin: 0 auto;
  }

  .top-row {
    display: flex;

    .col {
      margin-right: 25px;
      flex: 1;

      &:last-child {
        margin-right: 0;
      }
    }

    @include max-width($mobile) {
      flex-direction: column;

      .col {
        margin-right: 0;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .links {
    display: flex;
    a {
      display: block;
      padding: 4px 0;
      margin-bottom: 5px;
    }

    > * {
      flex: 1;
      margin-right: 25px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .main-links {
    display: flex;

    > * {
      flex: 1;
      margin-right: 40px;
      &:last-child {
        margin-right: 0;
      }
    }

    a {
      display: block;
      flex: 1;
      font-size: 16px;
      color: #fff;
      font-weight: 500;
      padding: 6px 0;
      margin-bottom: 15px;
      white-space: nowrap;
    }
  }

  .customer-service {
    p {
      color: #fff;
      font-weight: 500;
      font-size: 16px;
      margin-top: 0;
      margin-bottom: 24px;
    }
    @include max-width($mobile) {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        text-align: center;
      }
    }
  }

  a,
  h3 {
    color: #fff;
    text-decoration: none;
  }

  h3 {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 18px;
    margin-top: 0;
  }

  p {
    font-size: 16px;
    font-weight: 400;
  }

  a {
    font-size: 16px;
    font-weight: 500;
    transition: all 150ms ease;

    &:not(.btn) {
      &:hover {
        color: $lightblue;
      }
    }
    &.btn {
      font-weight: 600;
    }
  }

  .bottom-row {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .text {
      color: #fff;
      margin: 18px 0;

      @include max-width($mobile) {
        display: none;
      }
    }

    .text-mobile {
      color: #fff;
      margin: 18px 0;
      @include min-width($mobile) {
        display: none;
      }

      span {
        display: block;
        text-align: center;
        margin-top: 6px;
      }
    }

    a {
      font-size: 16px;
      margin-bottom: 0;
      transition: all 150ms ease;

      &:hover {
        color: $lightblue;
      }

      i {
        font-size: 22px;
      }
    }
  }
}
