body {
  font-family: sofia-pro-soft, sans-serif;
  font-weight: 400;
  font-style: normal;
  overflow-x: hidden;
  color: $body;
}

h1 {
  font-size: 35px;
  font-weight: 500;
  line-height: 40px;

  @include max-width($mobile) {
    font-size: 31px;
  }
}

h3 {
  font-size: 19px;
  line-height: 23px;
  letter-spacing: 0.85px;
}

p {
  letter-spacing: 0.17px;
  color: $body;
  font-weight: 500;
}

.text-blue {
  color: $blue;
}

.text-lightblue {
  color: $lightblue;
}

.text-black {
  color: $black;
}

.text-green {
  color: $green;
}
