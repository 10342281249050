.header {
  padding: 8px 5px;
  position: relative;

  .inner {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  .links {
    display: flex;
    align-items: center;
    margin: 0;
    margin-left: auto;
    padding: 0;
    list-style: none;

    li {
      padding: 8px 0;
    }

    @include max-width($mobile) {
      display: none;
      position: absolute;
      height: auto;
      top: 100%;
      right: 0;
      left: 0;
      width: 100%;
      flex-direction: column;
      padding-bottom: 55px;
      background-color: #fff;
      z-index: 10;
      margin: 0;
      align-items: flex-end;

      li {
        line-height: 1;
        height: auto;
        padding: 8px 16px 8px 8px;
        text-align: left;
      }

      .link {
        height: auto;
        line-height: 1;
      }

      &.visible {
        display: flex;
      }
    }
  }

  .link {
    font-size: 17px;
    color: $blacker;
    text-decoration: none;
    padding: 6px 15px;
    transition: all 150ms ease;

    &:hover,
    &.active {
      color: $blue;
    }
  }

  .btn {
    margin-left: 6px;
  }

  &.isShadowed {
    background-color: #fdfdfd;
    box-shadow: 0px 1px 8px 0 rgba(57, 57, 57, 0.22);

    .links {
      @include max-width($mobile) {
        background-color: #fff;

        .link {
          color: $blacker;
          text-shadow: none;
        }
      }
    }
  }

  .toggle {
    display: none;
    @include max-width($mobile) {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      height: 24px;
      width: 24px;
      right: 18px;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    svg {
      color: $green;
    }
  }

  @include max-width($mobile) {
    &.open {
      background-color: #fff;
    }
    .logo {
      padding: 4px 10px;
      img {
        max-height: 40px;
      }
    }
  }
}
