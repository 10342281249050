.btn {
  padding: 11px 22px;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.5px;
  outline: none;
  border: none;
  border-radius: 19.5px;
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  transition: all 150ms ease;

  &.primary {
    background-color: $blue;

    &:hover {
      background-color: darken($blue, 7%);
    }
  }

  &.green {
    background-color: $green;

    &:hover {
      background-color: darken($green, 7%);
    }
  }

  &.transparent {
    background-color: transparent;
    color: $green;
  }

  &:hover {
    box-shadow: 0 0 13px 0 rgba(74, 74, 74, 0.15);
  }
}
