.customer-service {
  position: relative;
  margin-bottom: 70px;

  .right-images {
    position: absolute;
    width: 352px;
    height: 420px;
    right: 0;
    top: 0;
    z-index: -1;

    .right-big {
      position: absolute;
      right: -82%;
      top: -72%;
    }

    .right-small {
      position: absolute;
      right: 18%;
      top: 18%;
      z-index: -1;
    }
  }

  .left-images {
    position: absolute;
    width: 178px;
    height: 617px;
    left: 0;
    bottom: 0;
    z-index: -1;

    .left-big {
      position: absolute;
      left: -435px;
      bottom: 0;
    }

    .left-small {
      position: absolute;
      left: -50px;
      bottom: 0;
      z-index: -1;
    }
  }

  .text {
    img {
      margin-bottom: 45px;
    }
  }

  .form {
    margin: 35px auto;
    max-width: 615px;

    .button {
      display: flex;
      justify-content: center;
    }
  }

  @include max-width($mobile) {
    .left-images,
    .right-images {
      display: none;
    }
  }
}

.input-row {
  display: flex;
  margin-bottom: 40px;
  > * {
    margin-right: 37px;
    flex: 1;
    &:last-child {
      margin-right: 0;
    }
  }

  @include max-width($mobile) {
    flex-direction: column;

    > * {
      width: 100%;
      margin-right: 0;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.input-wrap {
  label {
    color: $black;
    font-size: 17px;
    line-height: 23px;
    letter-spacing: 0.85px;
    font-weight: 600;
    display: block;
    margin-bottom: 24px;
  }

  input,
  textarea {
    padding: 13px 17px;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    background-color: #f6f6f6;
    font-size: 17px;
    line-height: 23px;
    color: $blacker;
    resize: none;
    width: 100%;
    transition: all 150ms ease;

    &::placeholder {
      color: #a6a6a6;
      letter-spacing: 0.17px;
    }

    &:focus {
      outline: none;
      border-color: $lightblue;
    }
  }
}

.contact-info {
  margin-top: 32px;
  display: flex;
  justify-content: center;

  a {
    color: $green;
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }

  @include max-width($mobile) {
    flex-direction: column;
    align-items: center;
    a {
      margin-bottom: 10px;
      margin-right: 0;
    }
  }
}
