.feedback {
  background-color: $green;
  padding-bottom: 290px;
}

.testimonials {
  background-color: #fff;
  margin-bottom: -270px;

  *,
  *:focus {
    outline: none !important;
  }

  .slider {
    transform: translateY(-270px);
    max-width: 980px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 60px 0 0;

    > * {
      flex: 1;
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }
    }

    @include max-width($mobile) {
      > * {
        margin-right: 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .slider-item {
    margin: 10px;
    position: relative;
    min-height: 440px;
    padding: 22px 28px;
    text-align: center;
    max-width: 670px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 7px 13px 0 rgba(73, 103, 33, 0.15);
    background-color: #fff;
    border-radius: 5px;

    h2 {
      color: $blue;
      font-size: 20px;
      font-weight: 500;
      margin: 22px 0 37px;
    }

    p {
      margin: 0 0 35px;
    }

    h3 {
      color: $black;
    }

    .circle {
      position: absolute;
      height: 100px;
      width: 100px;
      margin: 0 auto;
      left: 0;
      right: 0;
      top: -50px;
    }

    @include max-width($mobile) {
      height: auto;
      margin-left: 23px;
      padding: 55px 15px 35px;
    }
  }
}

.partner-logos {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;

  .logo {
    flex: 1;
    display: block;
    opacity: 0.7;
    transition: all 150ms ease;
    padding: 0 30px;

    &:hover {
      opacity: 1;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  @include max-width($mobile) {
    flex-wrap: wrap;
    padding-top: 25px;
    padding-bottom: 25px;

    .logo {
      padding-left: 10px;
      padding-right: 10px;
      width: 50%;
      flex: 1 0 auto;
      text-align: center;

      img {
        max-width: 150px;
        display: inline-block;
      }
    }
  }
}
