.features {
  margin-top: 64px;
  position: relative;
  padding-bottom: 30px;
  .inner {
    max-width: 1000px;
    margin: 0 auto 25px;
    display: flex;
    justify-content: space-between;

    .feature {
      &:last-child {
        margin-right: 0;
      }
    }

    @include max-width($mobile) {
      flex-direction: column;
      .feature {
        margin-right: 0;
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .show-more {
    text-align: center;

    input {
      opacity: 0;
    }
    .less {
      display: none;
    }
    label {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      color: $green;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
      font-weight: 600;
      display: inline-block;

      transition: all 150ms ease;

      &:hover {
        color: $lightblue;

        div {
          border-right-color: $lightblue;
          border-bottom-color: $lightblue;
        }
      }

      .chevron-down {
        position: relative;
        bottom: 3px;
      }
    }
  }

  .hidden-features {
    display: none;
  }

  input {
    &:checked {
      ~ .hidden-features,
      ~ label .less {
        display: block;
      }

      ~ label .more {
        display: none;
      }
    }
  }
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
  margin-right: 65px;
}

.icon {
  margin-bottom: 35px;
  min-height: 72px;
  max-width: 54px;

  img {
    max-width: 100%;
    height: auto;
  }

  @include max-width($mobile) {
    min-height: 0;
    margin-bottom: 20px;
  }
}

h3 {
  margin-bottom: 26px;
  margin-top: 0;
  color: $black;
}

p {
  margin: 0;
  color: $black;
}

.features-grid {
  display: flex;
  flex-wrap: wrap;
  max-width: 1090px;
  margin: 0 auto 35px;

  > * {
    flex: 1 0 auto;
    width: 33.3333%;
    margin-bottom: 65px;
    margin-right: 0;
    padding: 0 30px;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @include max-width($mobile) {
    flex-direction: column;
    > * {
      width: 100%;
      margin-bottom: 35px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .show-more {
    text-align: center;

    span {
      color: $green;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
      font-weight: 600;
      display: inline-block;
      transition: all 150ms ease;

      &:hover {
        color: $lightblue;

        > div {
          border-right-color: $lightblue;
          border-bottom-color: $lightblue;
        }
      }

      .chevron-down {
        position: relative;
        bottom: 3px;
      }
    }
  }
}
