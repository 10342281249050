@mixin max-width($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin min-width($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}
