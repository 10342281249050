.modal-inner {
  padding: 41px 47px;
  border-radius: 5px;
  box-shadow: 0px 7px 13px 0 rgba(74, 74, 74, 0.15);
  background-color: #fdfdfd;
  width: 85%;
  max-width: 1050px;

  .button {
    margin-top: 15px;
    display: flex;
    justify-content: center;
  }

  animation-name: animatetop;
  animation-duration: 0.4s;
}

.modal-outer {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);

  &.active {
    display: block;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

.get-started-form {
  max-width: 940px;
  margin: 0 auto;

  .provide-contact {
    margin: 45px auto;
    text-align: center;
  }

  .button {
    margin-top: 15px;
    display: flex;
    justify-content: center;
  }
}
