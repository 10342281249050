.hero {
  background-image: url('/images/hero.png');
  background-position: right center;
  background-repeat: no-repeat;
  min-height: 530px;

  .section-heading {
    padding-top: 105px;
    max-width: 1000px;
    margin-left: auto;

    .animated {
      animation: slogan 3000ms 3000ms infinite;
    }

    p {
      max-width: 515px;
    }
  }

  @include max-width($mobile) {
    min-height: 0;
    margin-bottom: 45px;
    background-position: 85% center;

    .section-heading {
      margin-top: 0;
      padding-top: 165px;
      h3 {
        text-align: right;
        b {
          display: block;
        }
      }
    }
  }
}

@keyframes slogan {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
